/* Dark gradient background and slightly softer text color */
#page-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* changed from center so content isn't forced to middle */
    min-height: calc(100vh - var(--unclass-header-height) - var(--login-header-height)); 
    background-color: #111217 !important;
    color: #f8f9fa;
    overflow-y: scroll;
  }

  #full-page-background {
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* changed from center so content isn't forced to middle */
    min-height: calc(100vh - var(--unclass-header-height) - var(--login-header-height));
    background-color: #111217 !important;
    color: #f8f9fa;
  }
  
  /* Container styling with subtle box-shadow */
  .settings-section {
    width: 100%;
    max-width: 1200px;
    margin: 20px 0;
    background-color: #212529;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.5);
  }

  .settings-section-title {
      font-size: 1.5rem;
      font-weight: 600;
      margin-bottom: 1.5rem;
  }

  .accordion-body {
    max-height: 70vh;
    overflow-y: scroll;
    border-radius: 8px;
  }

  
  /* Responsive design for smaller screens */
  @media (max-width: 768px) {
    .settings-section {
        max-width: 90%; /* Adjust max-width for mobile devices */
        padding: 15px; /* Optional: adjust padding for better fit */
    }
  }
  
  /* Give the section headers a bit more emphasis */
  .settings-section-title, .settings-subsection-title {
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .settings-subsection-title-left {
    color: white; /* highlight them in a warm accent color */
    margin-bottom: 15px;
    flex-shrink: 1;
    margin-right: 20px;
  }
  
  /* Smaller tweak to ensure the text is easily readable */
  .settings-subsection p {
    color: #ced4da; 
    margin-bottom: 10px;
  }
  
  /* Current IP text with a lighter accent color */
  .current-ip {
    color: #ffc107;
    font-weight: 500;
  }
  
  /* Make row text a bit softer and add a hover effect */
  .table-row {
    color: #f8f9fa;
    transition: background-color 0.2s ease;
  }
  
  .table-row:hover {
    background-color: rgba(255,255,255,0.08);
  }
  
  /* A small color accent for success/fail icons in the first column */
  .table-row td:first-child {
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  /* Subtle scroll styling in the table body */
  tbody {
    scrollbar-width: thin;
    scrollbar-color: #444 #2b2b2b;
  }
  
  tbody::-webkit-scrollbar {
    width: 8px;
  }
  
  tbody::-webkit-scrollbar-track {
    background: #2b2b2b;
  }
  
  tbody::-webkit-scrollbar-thumb {
    background-color: #444;
    border-radius: 4px;
  }
  
  /* Make the copy button stand out a bit more */
  .btn-warning {
    font-weight: 500;
  }
  
  /* Provide some spacing/margin for the "Link your CAC/PKI Card" button */
  .btn-accounts {
    margin-top: 10px;
    width: 250px;
    font-size: 14px !Important;
  }
  
  /* Settings Accordion Styles */
  .settings-accordion .accordion-button {
    background-color: #1a1d20 !important;
    color: #fff !important;
    box-shadow: none !important;
    border: none !important;
    padding: 1rem;
  }
  
  .settings-accordion .accordion-button:not(.collapsed) {
    background-color: #1a1d20 !important;
    box-shadow: none !important;
  }
  
  .settings-accordion .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  }
  
  .settings-accordion .accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
  }
  
  .settings-accordion .accordion-item {
    background-color: transparent !important;
  }
  
  .settings-accordion .settings-section-title {
    color: #fff;
    font-size: 1rem;
    letter-spacing: 0.1em;
  }
  
  /* Remove focus outline */
  .settings-accordion .accordion-button:focus {
    border: none;
    box-shadow: none !important;
  }
  
  /* Hover state */
  .settings-accordion .accordion-button:hover {
    background-color: #2c3034 !important;
    transition: background-color 0.2s ease;
  }
  
  .react-datepicker {
    background-color: #1a1d20 !important;
    border-color: #495057 !important;
    font-family: inherit !important;
  }

  .react-datepicker__header {
    background-color: #2a2d30 !important;
    border-bottom-color: #495057 !important;
  }

  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__time-name {
    color: white !important;
  }

  .react-datepicker__day,
  .react-datepicker__time-list-item {
    color: #dee2e6 !important;
    background-color: #1a1d20 !important;
  }

  .react-datepicker__day:hover,
  .react-datepicker__time-list-item:hover {
    background-color: #495057 !important;
  }

  .react-datepicker__day--selected,
  .react-datepicker__time-list-item--selected {
    background-color: #0d6efd !important;
    color: white !important;
  }

  .react-datepicker__time-container,
  .react-datepicker__time-box {
    background-color: #1a1d20 !important;
    border-color: #495057 !important;
  }

  .react-datepicker__navigation-icon::before {
    border-color: #dee2e6 !important;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: #0d6efd !important;
    color: white !important;
  }

  .react-datepicker__time-list {
    background-color: #1a1d20 !important;
  }

  .react-datepicker-popper {
    z-index: 9999 !important;
  }
  
  /* 
    Normal borders don't work with a sticky table header 
    https://stackoverflow.com/questions/50361698/border-style-do-not-work-with-sticky-position-element
   */
  .search-table {
    border-collapse: separate;
    border-spacing: 0;
  }

  .search-table th {
    /* Apply both top and bottom borders to the <th> */
    border-top: 1px solid rgb(77, 81, 84) !important;
    border-bottom: 1px solid rgb(77, 81, 84) !important;
    border-right: 1px solid rgb(77, 81, 84) !important;
  }

  .search-table td {
    /* For cells, apply the border to one of each side only (right but not left, bottom but not top) */
    border-bottom: 1px solid rgb(77, 81, 84) !important;
    border-right: 1px solid rgb(77, 81, 84) !important;
  }

  .search-table  th:first-child,
  .search-table  td:first-child {
    /* Apply a left border on the first <td> or <th> in a row */
    border-left: 1px solid rgb(77, 81, 84) !important;
  }

  .settings-group-list {
    max-height: 100px;
    overflow-y: auto;
    background-color: #212529;
    border-radius: 4px;
    padding: 8px;
  }