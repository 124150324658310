.loginscreen {
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;  
    top: var(--unclass-header-height);              
    left: 0;             
    width: 100%;        
    height: calc(100vh - var(--unclass-header-height));
}

body,
canvas {
    background-color: black;
    position: absolute;
    width: 100%;
    height: calc(100% - var(--unclass-header-height));
    margin: 0;
    padding: 0;
}

.small {
    font-weight: 200;
}
.white {
    color: white;
}
.place-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form-control {
    background-color: #212529;
    color: white;
    border: 1px solid #495057;
}

.form-control:focus {
    background-color: #212529;
    color: white;
    border-color: #495057;
    box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.25);
}

.form-control::placeholder {
    color: #6c757d;
}

.login-table-cell {
    vertical-align: middle;
}

.horizontal-scroll-overflow {
    overflow-x: scroll;
    overflow-y: hidden;
}

.hidden-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    vertical-align: middle;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scrollbar::-webkit-scrollbar {
    display: none;
}

.login-table {
    height: 50vh;
    overflow-y: scroll;
    border-top: 1px solid rgb(77, 81, 84);
}

.login-table-head tr {
    position: sticky;
    top: 0px;
    border-top: none !important;
    border-bottom: none !important;
    box-shadow: inset 0 2px 0 rgb(77, 81, 84), inset 0 -2px 0 rgb(77, 81, 84);
    padding: 2px 0;
}

.settings-subsection {
    margin-bottom: 1.5rem;
}

.settings-subsection-title {
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 0.75rem;
}

.btn-logout {
    width: 120px;
}

.current-ip {
    font-size: 0.9rem;
    opacity: 0.9;
}

.responsive-form {
    width: 650px;
}

@media (max-width: 768px) {
    .responsive-form {
        width: 400px;
    }
}

.responsive-font {
    font-size: 3vw;
}
@media screen and (min-width: 600px) {
    .responsive-font {
        font-size: 16px;
    }
}