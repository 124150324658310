.tle-table  {
    width: 100%;
    border-collapse: collapse;
    position: sticky;
    top: 0;
}
.tle-table th, td {
    border: 1px solid black; /* specify style and color */
    padding: 5px;
    text-align: left;
    cursor: pointer;
}
.tle-table th {
    color: rgb(255, 255, 255);
    position: sticky;
    top: 0;
    background: rgba(75, 75, 75, 1.0); /* To ensure the text below doesn't show when scrolling */
    border-top: 2px; /* To ensure that the header covers all lines as they are scrolled without leaking */
}
.tle-table tr:nth-child(even) {
    background-color: #000000;
}
.tle-table tr:hover {
    background-color: rgba(61, 113, 217, 0.20); /* specify a different background color on hover */
}

.tle-table .row-selected {
    background-color: rgba(61, 113, 217, 0.20) !important; /* specify a different background color on hover */
}

.tle{
    resize: none;
    font-family: monospace;
}

#custom-tle-descr {
    color: red;
}