.sidebar {
    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
    will-change: transform;
    backface-visibility: hidden;
    perspective: 1000px;
}

.sidebar-backdrop.show {
    opacity: 0.5 !important;
    transition: opacity 0.15s linear !important;
}

.sidebar-backdrop {
    opacity: 0 !important;
    transition: opacity 0.15s linear !important;
    background-color: #000 !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 1040 !important;
    width: 100vw !important;
    height: 100vh !important;
}

.sidebar-link {
    display: flex !important;
    align-items: center !important;
    color: white !important;
    text-decoration: none !important;
    padding: 10px 15px !important;
    font-size: 16px !important;
    border-radius: 4px !important;
    transition: all 0.2s ease-in-out !important;
    text-align: left !important;
    will-change: background-color, transform;
}

.sidebar-link:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
    transform: translateX(5px);
}

.sidebar-link:active {
    transform: translateX(2px);
}

.icon {
    margin-right: 10px !important;
    display: inline-flex;
    align-items: center;
}

/* Force hardware acceleration */
.offcanvas.show {
    transform: translateZ(0);
}

.login-logo {
    will-change: transform;
    backface-visibility: hidden;
    transform: translateZ(0);
}

/* Optimize closing animation */
.offcanvas.hiding {
    transition: transform 0.15s ease-in !important;
}

/* Ensure backdrop is clickable */
.offcanvas-backdrop {
    pointer-events: auto !important;
    cursor: pointer !important;
}